import { PermissionEnum } from '@/enums/PermissionEnum';
import { ImportKeys } from '@/enums/RouteKeyEnums';
import { Import } from '@/enums/RouteNameEnums';

export default [
    {
        title: 'Import',
        icon: { icon: 'mdi-import' },
        children: [
            {
                title: Import.BatchList,
                to: Import.BatchList,
                action: PermissionEnum.ImportRead,
                favoriteIcon: { icon: 'mdi-format-list-numbered' },
                key: ImportKeys.BatchList,
            },
        ],
    },
];
