export enum Auth {
    Login = 'Login',
    ForgotPassword = 'ForgotPassword',
    ResetPassword = 'ResetPassword',
    CreatePassword = 'CreatePassword',
}

export enum Error {
    Error404 = 'Error404',
    Error403 = 'Error403'
}

export enum General {
    Home = 'Home',
    Default = 'Dashboard',
    Profile = 'Profile',
}

export enum System {
    Users = 'Users',
    UserCreate = 'UserCreate',
    UserEdit = 'UserEdit',
    UserWatching = 'UserWatching',
    UserAssignments = 'UserAssignments',
    UserAbsences = 'UserAbsences',
    ActivityLog = 'ActivityLog',
    Languages = 'Languages',
    LanguageCreate = 'LanguageCreate',
    LanguageEdit = 'LanguageEdit',
    ActivityLogs = 'ActivityLogs',
    Currencies = 'Currencies',
    CurrencyCreate = 'CurrencyCreate',
    CurrencyEdit = 'CurrencyEdit',
    Taxes = 'Taxes',
    TaxCreate = 'TaxCreate',
    TaxEdit = 'TaxEdit',
    TaxGroups = 'TaxGroups',
    TaxGroupCreate = 'TaxGroupCreate',
    TaxGroupEdit = 'TaxGroupEdit',
    Countries = 'Countries',
    CountryCreate = 'CountryCreate',
    CountryEdit = 'CountryEdit',
    Carriers = 'Carriers',
    CarrierCreate = 'CarrierCreate',
    CarrierEdit = 'CarrierEdit',
    Domains = 'Domains',
    DomainCreate = 'DomainCreate',
    DomainEdit = 'DomainEdit',
    PaymentMethods = 'PaymentMethods',
    PaymentMethodCreate = 'PaymentMethodCreate',
    PaymentMethodEdit = 'PaymentMethodEdit',
    Companies = 'Companies',
    CompanyCreate = 'CompanyCreate',
    CompanyEdit = 'CompanyEdit',
    Translations = 'Translations',
    TranslationCreate = 'TranslationCreate',
    TranslationEdit = 'TranslationEdit',
}

export enum Suppliers {
    SupplierList = 'Suppliers',
    SupplierCreate = 'SupplierCreate',
    SupplierDetail = 'SupplierDetail',
}

export enum Crm {
    CustomerList = 'Customers',
    CustomerEdit = 'CustomerEdit',
    CustomerCreate = 'CustomerCreate',
    CustomerOverview = 'CustomerOverview',
    CustomerAddresses = 'CustomerAddresses',
    CustomerOrders = 'CustomerOrders',
    CustomerInvoices = 'CustomerInvoices',
    CustomerComplaints = 'CustomerComplaints',
    CustomerRequests = 'CustomerRequests',
    CustomerCarts = 'CustomerCarts',
    BlacklistList = 'Blacklist',
    BlacklistCreate = 'BlacklistCreate',
    BlacklistEdit = 'BlacklistEdit',
}

export enum Marketing {
    VoucherList = 'Vouchers',
    VoucherCreate = 'VoucherCreate',
    VoucherDetail = 'VoucherDetail',
}

export enum Vehicles {
    ProducerList = 'Producers',
    ProducerEdit = 'ProducerEdit',
    ProducerCreate = 'ProducerCreate',
    ModelList = 'Models',
    ModelEdit = 'ModelEdit',
    ModelCreate = 'ModelCreate',
    SchemaList = 'Schemas',
    SchemaCreate = 'SchemaCreate',
    SchemaEdit = 'SchemaEdit',
}

export enum Products {
    ProductList = 'Products',
    ProductCreate = 'ProductCreate',
    ProductEdit = 'ProductEdit',
    ParametersList = 'Parameters',
    ParameterCreate = 'ParameterCreate',
    ParameterEdit = 'ParameterEdit',
    CategoryList = 'ProductCategories',
    CategoryCreate = 'ProductCategoryCreate',
    CategoryEdit = 'ProductCategoryEdit',
}

export enum Admin {
    Pulse = 'Pulse',
}

export enum EmailTemplates {
    EmailTemplatesList = 'EmailTemplates',
    EmailTemplateCreate = 'EmailTemplateCreate',
    EmailTemplateDetail = 'EmailTemplateDetail',
}

export enum Orders {
    OrderList = 'Orders',
    OrderDetail = 'OrderDetail',
    SupplierOrderList = 'SupplierOrders',
    SupplierOrderDetail = 'SupplierOrderDetail',
    SupplierOrderNew = 'SupplierOrderNew',
}

export enum Import {
    BatchList = 'BatchList',
    BatchDetail = 'BatchDetail',
    NewImport = 'NewImport',
}
