import { PermissionEnum } from '@/enums/PermissionEnum';
import { SystemKeys } from '@/enums/RouteKeyEnums';
import { System } from '@/enums/RouteNameEnums';

export default [
    {
        name: System.Users,
        path: '/system/users',
        component: () => import('@/views/system/users/views/SystemUserList.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default',
            canAddToFavorite: true,
            permissions: { read: PermissionEnum.UsersRead },
            key: SystemKeys.Users,
        },
    },
    {
        name: System.UserEdit,
        path: '/system/users/:id', component: () => import('@/views/system/users/views/SystemUserDetail.vue'),
        props: (route: { params: { id: number; } }) => ({ id: route.params.id }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            navActiveLink: System.Users,
            permissions: { read: PermissionEnum.UsersRead },
            key: SystemKeys.UserEdit,
        },
    },
    {
        name: System.UserCreate,
        path: '/system/users/create', component: () => import('@/views/system/users/views/SystemUserDetail.vue'),
        props: () => ({ id: 'create' }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            create: true,
            navActiveLink: System.Users,
            permissions: { write: PermissionEnum.UsersWrite },
            key: SystemKeys.UserCreate,
        },
    },
    {
        name: System.UserWatching,
        path: '/system/users/:id/watching', component: () => import('@/views/system/users/views/SystemUserWatching.vue'),
        props: (route: { params: { id: number; } }) => ({ id: route.params.id }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            navActiveLink: System.Users,
            permissions: { read: PermissionEnum.UsersRead },
            key: SystemKeys.UserWatching,
        },
    },
    {
        name: System.UserAssignments,
        path: '/system/users/:id/assignments', component: () => import('@/views/system/users/views/SystemUserAssignments.vue'),
        props: (route: { params: { id: number; } }) => ({ id: route.params.id }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            navActiveLink: System.Users,
            permissions: { read: PermissionEnum.UsersRead },
            key: SystemKeys.UserAssignments,
        },
    },
    {
        name: System.UserAbsences,
        path: '/system/users/:id/absences', component: () => import('@/views/system/users/views/SystemUserAbsences.vue'),
        props: (route: { params: { id: number; } }) => ({ id: route.params.id }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            navActiveLink: System.Users,
            permissions: { read: PermissionEnum.UsersRead },
            key: SystemKeys.UserAbsences,
        },
    },
    {
        name: System.ActivityLog,
        path: '/system/users/:id/activities', component: () => import('@/views/system/users/views/SystemActivityLog.vue'),
        props: (route: { params: { id: number; } }) => ({ id: route.params.id }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            navActiveLink: System.Users,
            permissions: { read: PermissionEnum.UsersRead },
            key: SystemKeys.ActivityLog,
        },
    },
    {
        name: System.Languages,
        path: '/system/languages', component: () => import('@/views/system/languages/LanguageList.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default',
            canAddToFavorite: true,
            permissions: { read: PermissionEnum.LanguagesRead },
            key: SystemKeys.Languages,
        },
    },
    {
        name: System.LanguageCreate,
        path: '/system/languages/create', component: () => import('@/views/system/languages/LanguageDetail.vue'),
        props: () => ({ id: 'create' }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            create: true,
            navActiveLink: System.Languages,
            permissions: { write: PermissionEnum.LanguagesWrite },
            key: SystemKeys.LanguageCreate,
        },
    },
    {
        name: System.LanguageEdit,
        path: '/system/languages/:id/', component: () => import('@/views/system/languages/LanguageDetail.vue'),
        props: (route: { params: { id: number; } }) => ({ id: route.params.id }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            navActiveLink: System.Languages,
            permissions: { read: PermissionEnum.LanguagesRead },
            key: SystemKeys.LanguageEdit,
        },
    },
    {
        name: System.ActivityLogs,
        path: '/system/activity-log', component: () => import('@/views/system/activityLogs/views/ActivityLogList.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default',
            canAddToFavorite: true,
            permissions: { read: PermissionEnum.ActivityLogsRead },
            key: SystemKeys.ActivityLogs,
        },
    },
    {
        name: System.Currencies,
        path: '/system/currencies', component: () => import('@/views/system/currencies/CurrencyList.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default',
            canAddToFavorite: true,
            permissions: { read: PermissionEnum.CurrenciesRead },
            key: SystemKeys.Currencies,
        },
    },
    {
        name: System.CurrencyCreate,
        path: '/system/currencies/create', component: () => import('@/views/system/currencies/CurrencyDetail.vue'),
        props: () => ({ id: 'create' }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            create: true,
            navActiveLink: System.Currencies,
            permissions: { write: PermissionEnum.CurrenciesWrite },
            key: SystemKeys.CurrencyCreate,
        },
    },
    {
        name: System.CurrencyEdit,
        path: '/system/currencies/:id/', component: () => import('@/views/system/currencies/CurrencyDetail.vue'),
        props: (route: { params: { id: number; } }) => ({ id: route.params.id }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            navActiveLink: System.Currencies,
            permissions: { read: PermissionEnum.CurrenciesRead },
            key: SystemKeys.CurrencyEdit,
        },
    },
    {
        name: System.Taxes,
        path: '/system/taxes', component: () => import('@/views/system/taxes/TaxList.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default',
            canAddToFavorite: true,
            permissions: { read: PermissionEnum.TaxesRead },
            key: SystemKeys.Taxes,
        },
    },
    {
        name: System.TaxCreate,
        path: '/system/taxes/create', component: () => import('@/views/system/taxes/TaxDetail.vue'),
        props: () => ({ id: 'create' }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            create: true,
            navActiveLink: System.Taxes,
            permissions: { write: PermissionEnum.TaxesWrite },
            key: SystemKeys.TaxCreate,
        },
    },
    {
        name: System.TaxEdit,
        path: '/system/taxes/:id/', component: () => import('@/views/system/taxes/TaxDetail.vue'),
        props: (route: { params: { id: number; } }) => ({ id: route.params.id }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            navActiveLink: System.Taxes,
            permissions: { read: PermissionEnum.TaxesRead },
            key: SystemKeys.TaxEdit,
        },
    },
    {
        name: System.TaxGroups,
        path: '/system/tax-groups', component: () => import('@/views/system/taxGroups/views/TaxGroupList.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default',
            canAddToFavorite: true,
            permissions: { read: PermissionEnum.TaxesRead },
            key: SystemKeys.TaxGroups,
        },
    },
    {
        name: System.TaxGroupCreate,
        path: '/system/tax-groups/create', component: () => import('@/views/system/taxGroups/views/TaxGroupDetail.vue'),
        props: () => ({ id: 'create' }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            create: true,
            navActiveLink: System.TaxGroups,
            permissions: { write: PermissionEnum.TaxesWrite },
            key: SystemKeys.TaxGroupCreate,
        },
    },
    {
        name: System.TaxGroupEdit,
        path: '/system/tax-groups/:id/', component: () => import('@/views/system/taxGroups/views/TaxGroupDetail.vue'),
        props: (route: { params: { id: number; } }) => ({ id: route.params.id }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            navActiveLink: System.TaxGroups,
            permissions: { read: PermissionEnum.TaxesRead },
            key: SystemKeys.TaxGroupEdit,
        },
    },
    {
        name: System.Countries,
        path: '/system/countries', component: () => import('@/views/system/countries/views/CountryList.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default',
            canAddToFavorite: true,
            permissions: { read: PermissionEnum.CountriesRead },
            key: SystemKeys.Countries,
        },
    },
    {
        name: System.CountryCreate,
        path: '/system/countries/create', component: () => import('@/views/system/countries/views/CountryDetail.vue'),
        props: () => ({ id: 'create' }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            create: true,
            navActiveLink: System.Countries,
            permissions: { write: PermissionEnum.CountriesWrite },
            key: SystemKeys.CountryCreate,
        },
    },
    {
        name: System.CountryEdit,
        path: '/system/countries/:id/', component: () => import('@/views/system/countries/views/CountryDetail.vue'),
        props: (route: { params: { id: number; } }) => ({ id: route.params.id }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            create: false,
            navActiveLink: System.Countries,
            permissions: { read: PermissionEnum.CountriesRead },
            key: SystemKeys.CountryEdit,
        },
    },
    {
        name: System.Carriers,
        path: '/system/carriers', component: () => import('@/views/system/carriers/views/CarrierList.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default',
            canAddToFavorite: true,
            permissions: { read: PermissionEnum.CarriersRead },
            key: SystemKeys.Carriers,
        },
    },
    {
        name: System.CarrierCreate,
        path: '/system/carriers/create', component: () => import('@/views/system/carriers/views/CarrierDetail.vue'),
        props: () => ({ id: 'create' }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            create: true,
            navActiveLink: System.Carriers,
            permissions: { write: PermissionEnum.CarriersWrite },
            key: SystemKeys.CarrierCreate,
        },
    },
    {
        name: System.CarrierEdit,
        path: '/system/carriers/:id/', component: () => import('@/views/system/carriers/views/CarrierDetail.vue'),
        props: (route: { params: { id: number; } }) => ({ id: route.params.id }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            navActiveLink: System.Carriers,
            permissions: { read: PermissionEnum.CarriersRead },
            key: SystemKeys.CarrierEdit,
        },
    },
    {
        name: System.Domains,
        path: '/system/domains', component: () => import('@/views/system/domains/views/DomainList.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default',
            canAddToFavorite: true,
            permissions: { read: PermissionEnum.DomainsRead },
            key: SystemKeys.Domains,
        },
    },
    {
        name: System.DomainCreate,
        path: '/system/domains/create', component: () => import('@/views/system/domains/views/DomainDetail.vue'),
        props: () => ({ id: 'create' }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            create: true,
            navActiveLink: System.Domains,
            permissions: { write: PermissionEnum.DomainsWrite },
            key: SystemKeys.DomainCreate,
        },
    },
    {
        name: System.DomainEdit,
        path: '/system/domains/:id/', component: () => import('@/views/system/domains/views/DomainDetail.vue'),
        props: (route: { params: { id: number; } }) => ({ id: route.params.id }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            navActiveLink: System.Domains,
            permissions: { read: PermissionEnum.DomainsRead },
            key: SystemKeys.DomainEdit,
        },
    },
    {
        name: System.PaymentMethods,
        path: '/system/payments', component: () => import('@/views/system/paymentMethods/views/PaymentMethodList.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default',
            canAddToFavorite: true,
            permissions: { read: PermissionEnum.PaymentMethodsRead },
            key: SystemKeys.PaymentMethods,
        },
    },
    {
        name: System.PaymentMethodCreate,
        path: '/system/payments/create', component: () => import('@/views/system/paymentMethods/views/PaymentMethodDetail.vue'),
        props: () => ({ id: 'create' }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            create: true,
            navActiveLink: System.PaymentMethods,
            permissions: { write: PermissionEnum.PaymentMethodsWrite },
            key: SystemKeys.PaymentMethodCreate,
        },
    },
    {
        name: System.PaymentMethodEdit,
        path: '/system/payments/:id/', component: () => import('@/views/system/paymentMethods/views/PaymentMethodDetail.vue'),
        props: (route: { params: { id: number; } }) => ({ id: route.params.id }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            navActiveLink: System.PaymentMethods,
            permissions: { read: PermissionEnum.PaymentMethodsRead },
            key: SystemKeys.PaymentMethodEdit,
        },
    },
    {
        name: System.Companies,
        path: '/system/companies', component: () => import('@/views/system/companies/views/CompanyList.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default',
            canAddToFavorite: true,
            permissions: { read: PermissionEnum.CompaniesRead },
            key: SystemKeys.Companies,
        },
    },
    {
        name: System.CompanyCreate,
        path: '/system/companies/create', component: () => import('@/views/system/companies/views/CompanyDetail.vue'),
        props: () => ({ id: 'create' }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            create: true,
            navActiveLink: System.Companies,
            permissions: { write: PermissionEnum.CompaniesWrite },
            key: SystemKeys.CompanyCreate,
        },
    },
    {
        name: System.CompanyEdit,
        path: '/system/companies/:id/', component: () => import('@/views/system/companies/views/CompanyDetail.vue'),
        props: (route: { params: { id: number; } }) => ({ id: route.params.id }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            navActiveLink: System.Companies,
            permissions: { read: PermissionEnum.CompaniesRead },
            key: SystemKeys.CompanyEdit,
        },
    },
    {
        name: System.Translations,
        path: '/system/translations', component: () => import('@/views/system/translations/views/TranslationList.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default',
            canAddToFavorite: true,
            permissions: { read: PermissionEnum.TranslationsRead },
            key: SystemKeys.Translations,
        },
    },
    {
        name: System.TranslationCreate,
        path: '/system/translations/create', component: () => import('@/views/system/translations/views/TranslationDetail.vue'),
        props: () => ({ id: 'create' }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            create: true,
            navActiveLink: System.Translations,
            permissions: { write: PermissionEnum.TranslationsWrite },
            key: SystemKeys.TranslationCreate,
        },
    },
    {
        name: System.TranslationEdit,
        path: '/system/translations/:id/', component: () => import('@/views/system/translations/views/TranslationDetail.vue'),
        props: (route: { params: { id: number; } }) => ({ id: route.params.id }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            navActiveLink: System.Translations,
            permissions: { read: PermissionEnum.TranslationsRead },
            key: SystemKeys.TranslationEdit,
        },
    },
];
