import { useRoute } from 'vue-router';
import { handleSnackbarSuccess, showSnackbar } from './useSnackbarHelpers';
import i18n from '@/plugins/i18n';
import { useAccountStore } from '@/stores/accountStore';
import type { SaveObject } from '@/types/SaveObject';
import type { UserType } from '@/types/UserType';
import type { VFormErrorType } from '@/types/VFormErrorType';
import type { LanguageType } from '@/stores/modules/system/languages/languageStore';
import type { TranslationType } from '@/types/TranslationType';

export function isCreatePage(): boolean {
    const route = useRoute();

    return Boolean(route?.meta?.create);
}

export function formatBytes(bytes: number, decimals: number = 2): string {
    if (bytes === 0) {
        return '0 bytes';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export async function currentUser(): Promise<UserType | null> {
    const currentUser = await useAccountStore().fetch();

    return (currentUser)?.data.data ?? null;
};

export function chunkArray<T>(array: T[], size: number): T[][] {
    const chunked = [];
    for (let index = 0; index < array.length; index += size) {
        chunked.push(array.slice(index, size + index));
    }

    return chunked;
}

export function copyToClipboard(text: string): void {
    navigator.clipboard.writeText(text);
    const message = handleSnackbarSuccess(i18n.global.t('message.copiedToClipboard'));
    showSnackbar(message);
}

export function truncateText(text: string, length: number): string {
    return text.length > length ? text.slice(0, length) + '...' : text;
}

export function isCurrentUser(id: number|null): boolean {
    return Boolean(id && id === useAccountStore().getProfile?.id);
}

export function toSaveObject<TKey>(id: TKey, object: any): SaveObject<TKey> {
    const result = {
        id: id,
        data: JSON.parse(JSON.stringify(object)),
    } as SaveObject<TKey>;

    return result;
}

export function enumToArrayOfObjects(e: any, translationPrefix: string = '', useValueAsTranslationKey: boolean = false) {
    return Object.entries(e).map(([key, value]) => ({
        id: value,
        name: translationPrefix !== '' ? i18n.global.t(`${translationPrefix}${useValueAsTranslationKey ? value : key}`) : key,
    }));
}

export function isKeyOfEnum<T extends object>(enumObj: T, value: string): boolean {
    return Object.keys(enumObj).includes(value);
}

export function isValueOfEnum<T extends object>(enumObj: T, value: string): boolean {
    return Object.values(enumObj).includes(value as any);
}

export function can(action: string | string[]): boolean {
    return useAccountStore().hasPermission(action);
}

export function isErrorState(tabErrors: VFormErrorType[], keys: string | string[], errorPrefix: string = ''): boolean {
    const keysArray = Array.isArray(keys) ? keys : [keys];

    return keysArray.some(key =>
        tabErrors.some((error: VFormErrorType) =>
            error.id.toString().includes(`${errorPrefix}${key}`)),
    );
};

export function setTabTitle(
    { title, separator = ' | ', prefix = '', suffix = '' }:
    { title: string; separator?: string; prefix?: string; suffix?: string },
): void {
    const formattedTitle = `${prefix}${prefix ? separator : ''}${title}${suffix ? separator : ''}${suffix}`;
    document.title = formattedTitle;
}

export function replaceDecimalPointWithComma(value: number): string {
    return value.toString().replace('.', ',');
}

export function getTranslations(translations: TranslationType[], languages: LanguageType[]): string {
    const locale = i18n.global.locale.value;
    let translation = translations.find((t: TranslationType) => {
        const language = languages.find(lang => lang.id === t.language_id);

        return language && language.code === locale;
    });

    if (!translation) {
        translation = translations.find(t => t.language_id === 1);
    }

    return translation ? translation.title : '';
};

export function isAdmin(): boolean {
    return useAccountStore().getProfile?.admin ?? false;
}