import { PermissionEnum } from '@/enums/PermissionEnum';
import { OrdersKeys } from '@/enums/RouteKeyEnums';
import { Orders } from '@/enums/RouteNameEnums';

export default [
    {
        name: Orders.OrderList,
        path: '/orders',
        component: () => import('@/views/orders/orders/views/OrderList.vue'),
        meta: {
            requiresAuth: true,
            canAddToFavorite: true,
            layout: 'default',
            permissions: { read: PermissionEnum.OrdersRead },
            key: OrdersKeys.OrderList,
        },
    },
    {
        name: Orders.OrderDetail,
        path: '/orders/:id',
        component: () => import('@/views/orders/orders/views/OrderDetail.vue'),
        props: (route: { params: { id: number; } }) => ({ id: route.params.id }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            navActiveLink: Orders.OrderList,
            breadcrumb: [{ title: Orders.OrderList }],
            hideBreadcrumbs: true,
            permissions: { read: PermissionEnum.OrdersRead },
            key: OrdersKeys.OrderDetail,
        },
    },
    {
        name: Orders.SupplierOrderList,
        path: '/orders/suppliers',
        component: () => import('@/views/orders/suppliers/views/SupplierOrderList.vue'),
        meta: {
            requiresAuth: true,
            canAddToFavorite: true,
            layout: 'default',
            permissions: { read: PermissionEnum.OrdersRead },
            key: OrdersKeys.SupplierOrderList,
        },
    },
    {
        name: Orders.SupplierOrderDetail,
        path: '/orders/suppliers/:id',
        component: () => import('@/views/orders/suppliers/views/SupplierOrderDetail.vue'),
        props: (route: { params: { id: number; } }) => ({ id: route.params.id }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            navActiveLink: Orders.SupplierOrderList,
            breadcrumb: [{ title: Orders.SupplierOrderList }],
            permissions: { read: PermissionEnum.OrdersRead },
            key: OrdersKeys.SupplierOrderDetail,
        },
    },
    {
        name: Orders.SupplierOrderNew,
        path: '/orders/suppliers/new',
        component: () => import('@/views/orders/suppliers/views/SupplierOrderNew.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default',
            create: true,
            navActiveLink: Orders.SupplierOrderList,
            breadcrumb: [{ title: Orders.SupplierOrderList }],
            permissions: { write: PermissionEnum.OrdersWrite },
            key: OrdersKeys.SupplierOrderNew,
        },
    },
];
