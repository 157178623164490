import { PermissionEnum } from '@/enums/PermissionEnum';
import { EmailTemplatesKeys } from '@/enums/RouteKeyEnums';
import { EmailTemplates } from '@/enums/RouteNameEnums';

export default [
    {
        title: 'Emails',
        icon: { icon: 'mdi-email' },
        children: [
            {
                title: EmailTemplates.EmailTemplatesList,
                to: EmailTemplates.EmailTemplatesList,
                action: PermissionEnum.EmailsRead,
                favoriteIcon: { icon: 'mdi-email-edit' },
                key: EmailTemplatesKeys.EmailTemplatesList,
            },
        ],
        badgeClass: 'bg-global-primary',
    },
];
