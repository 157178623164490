import { PermissionEnum } from '@/enums/PermissionEnum';
import { ImportKeys } from '@/enums/RouteKeyEnums';
import { Import } from '@/enums/RouteNameEnums';

export default [
    {
        name: Import.BatchList,
        path: '/import/batches',
        component: () => import('@/views/import/views/ImportBatchList.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default',
            canAddToFavorite: true,
            permissions: { read: PermissionEnum.ImportRead },
            key: ImportKeys.BatchList,
        },
    },
    {
        name: Import.BatchDetail,
        path: '/import/batches/:id',
        component: () => import('@/views/import/views/ImportBatchDetail.vue'),
        props: (route: { params: { id: number; } }) => ({ id: route.params.id }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            canAddToFavorite: false,
            navActiveLink: Import.BatchList,
            permissions: { read: PermissionEnum.ImportRead },
            key: ImportKeys.BatchDetail,
        },
    },
    {
        name: Import.NewImport,
        path: '/import/new-import',
        component: () => import('@/views/import/views/ImportNew.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default',
            create: true,
            navActiveLink: Import.BatchList,
            permissions: { write: PermissionEnum.ImportWrite },
            key: ImportKeys.NewImport,
        },
    },
];