<script setup lang="ts">
interface Props {
  icon?: string
  iconSize?: string
}

const props = withDefaults(defineProps<Props>(), {
    icon: 'mdi-close',
    iconSize: '20',
});
</script>

<template>
    <IconBtn
        variant="elevated"
        class="v-dialog-close-btn"
    >
        <VIcon
            :icon="props.icon"
            :size="props.iconSize"
        />
    </IconBtn>
</template>
