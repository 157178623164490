import { PermissionEnum } from '@/enums/PermissionEnum';
import { EmailTemplatesKeys } from '@/enums/RouteKeyEnums';
import { EmailTemplates } from '@/enums/RouteNameEnums';

export default [
    {
        name: EmailTemplates.EmailTemplatesList,
        path: '/emails/templates',
        component: () => import('@/views/emails/templates/views/EmailTemplateList.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default',
            canAddToFavorite: true,
            permissions: { read: PermissionEnum.EmailsRead },
            key: EmailTemplatesKeys.EmailTemplatesList,
        },
    },
    {
        name: EmailTemplates.EmailTemplateCreate,
        path: '/emails/templates/create',
        component: () => import('@/views/emails/templates/views/EmailTemplateDetail.vue'),
        props: () => ({ id: 'create' }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            create: true,
            navActiveLink: EmailTemplates.EmailTemplatesList,
            permissions: { read: PermissionEnum.EmailsRead },
            key: EmailTemplatesKeys.EmailTemplateCreate,
        },
    },
    {
        name: EmailTemplates.EmailTemplateDetail,
        path: '/emails/templates/:id',
        component: () => import('@/views/emails/templates/views/EmailTemplateDetail.vue'),
        props: (route: { params: { id: number } }) => ({ id: route.params.id }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            navActiveLink: EmailTemplates.EmailTemplatesList,
            permissions: { read: PermissionEnum.EmailsRead },
            key: EmailTemplatesKeys.EmailTemplateDetail,
        },
    },
];
