import { PermissionEnum } from '@/enums/PermissionEnum';
import { OrdersKeys } from '@/enums/RouteKeyEnums';
import { Orders } from '@/enums/RouteNameEnums';

export default [
    {
        title: Orders.OrderList,
        icon: { icon: 'mdi-cart-variant' },
        children: [
            {
                title: Orders.OrderList,
                to: Orders.OrderList,
                action: PermissionEnum.OrdersRead,
                favoriteIcon: { icon: 'mdi-cart-variant' },
                key: OrdersKeys.OrderList,
            },
            {
                title: Orders.SupplierOrderList,
                to: Orders.SupplierOrderList,
                action: PermissionEnum.OrdersRead,
                favoriteIcon: { icon: 'mdi-domain' },
                key: OrdersKeys.SupplierOrderList,
            },
        ],
    },
];
