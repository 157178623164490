import { PermissionEnum } from '@/enums/PermissionEnum';
import { SystemKeys } from '@/enums/RouteKeyEnums';
import { System } from '@/enums/RouteNameEnums';

export default [
    {
        title: 'System',
        icon: { icon: 'mdi-cog' },
        children: [
            {
                title: System.Users,
                to: System.Users,
                action: PermissionEnum.UsersRead,
                favoriteIcon: { icon: 'mdi-account-key' },
                key: SystemKeys.Users,
            },
            {
                title: System.Languages,
                to: System.Languages,
                action: PermissionEnum.LanguagesRead,
                favoriteIcon: { icon: 'mdi-translate' },
                key: SystemKeys.Languages,
            },
            {
                title: System.ActivityLogs,
                to: System.ActivityLogs,
                action: PermissionEnum.ActivityLogsRead,
                favoriteIcon: { icon: 'mdi-math-log' },
                key: SystemKeys.ActivityLogs,
            },
            {
                title: System.Currencies,
                to: System.Currencies,
                action: PermissionEnum.CurrenciesRead,
                favoriteIcon: { icon: 'mdi-currency-eur' },
                key: SystemKeys.Currencies,
            },
            {
                title: System.Taxes,
                to: System.Taxes,
                action: PermissionEnum.TaxesRead,
                favoriteIcon: { icon: 'mdi-sack-percent' },
                key: SystemKeys.Taxes,
            },
            {
                title: System.TaxGroups,
                to: System.TaxGroups,
                action: PermissionEnum.TaxesRead,
                favoriteIcon: { icon: 'mdi-sack-percent' },
                key: SystemKeys.TaxGroups,
            },
            {
                title: System.Carriers,
                to: System.Carriers,
                action: PermissionEnum.CarriersRead,
                favoriteIcon: { icon: 'mdi-truck-delivery' },
                key: SystemKeys.Carriers,
            },
            {
                title: System.Countries,
                to: System.Countries,
                action: PermissionEnum.CountriesRead,
                favoriteIcon: { icon: 'mdi-earth' },
                key: SystemKeys.Countries,
            },
            {
                title: System.Domains,
                to: System.Domains,
                action: PermissionEnum.DomainsRead,
                favoriteIcon: { icon: 'mdi-domain' },
                key: SystemKeys.Domains,
            },
            {
                title: System.PaymentMethods,
                to: System.PaymentMethods,
                action: PermissionEnum.PaymentMethodsRead,
                favoriteIcon: { icon: 'mdi-cash' },
                key: SystemKeys.PaymentMethods,
            },
            {
                title: System.Companies,
                to: System.Companies,
                action: PermissionEnum.CompaniesRead,
                favoriteIcon: { icon: 'mdi-home-city' },
                key: SystemKeys.Companies,
            },
            {
                title: System.Translations,
                to: System.Translations,
                action: PermissionEnum.TranslationsRead,
                favoriteIcon: { icon: 'mdi-translate-variant' },
                key: SystemKeys.Translations,
            },
        ],
        badgeClass: 'bg-global-primary',
    },
];
