export enum AuthKeys {
    Login = 'login',
    ForgotPassword = 'forgot-password',
    ResetPassword = 'reset-password',
    CreatePassword = 'create-password',
}

export enum ErrorKeys {
    Error404 = 'error-404',
    Error403 = 'error-403',
}

export enum GeneralKeys {
    Home = 'home',
    Default = 'dashboard',
    Profile = 'profile',
}

export enum SystemKeys {
    Users = 'users',
    UserCreate = 'user-create',
    UserEdit = 'user-edit',
    UserWatching = 'user-watching',
    UserAssignments = 'user-assignments',
    UserAbsences = 'user-absences',
    ActivityLog = 'activity-log',
    Languages = 'languages',
    LanguageCreate = 'language-create',
    LanguageEdit = 'language-edit',
    ActivityLogs = 'activity-logs',
    Currencies = 'currencies',
    CurrencyCreate = 'currency-create',
    CurrencyEdit = 'currency-edit',
    Taxes = 'taxes',
    TaxCreate = 'tax-create',
    TaxEdit = 'tax-edit',
    TaxGroups = 'tax-groups',
    TaxGroupCreate = 'tax-group-create',
    TaxGroupEdit = 'tax-group-edit',
    Countries = 'countries',
    CountryCreate = 'country-create',
    CountryEdit = 'country-edit',
    Carriers = 'carriers',
    CarrierCreate = 'carrier-create',
    CarrierEdit = 'carrier-edit',
    Domains = 'domains',
    DomainCreate = 'domain-create',
    DomainEdit = 'domain-edit',
    PaymentMethods = 'payment-methods',
    PaymentMethodCreate = 'payment-method-create',
    PaymentMethodEdit = 'payment-method-edit',
    Companies = 'companies',
    CompanyCreate = 'company-create',
    CompanyEdit = 'company-edit',
    Translations = 'translations',
    TranslationCreate = 'translation-create',
    TranslationEdit = 'translation-edit',
}

export enum SuppliersKeys {
    SupplierList = 'suppliers',
    SupplierCreate = 'supplier-create',
    SupplierDetail = 'supplier-detail',
}

export enum CrmKeys {
    CustomerList = 'customers',
    CustomerEdit = 'customer-edit',
    CustomerCreate = 'customer-create',
    CustomerOverview = 'customer-overview',
    CustomerAddresses = 'customer-addresses',
    CustomerOrders = 'customer-orders',
    CustomerInvoices = 'customer-invoices',
    CustomerComplaints = 'customer-complaints',
    CustomerRequests = 'customer-requests',
    CustomerCarts = 'customer-carts',
    BlacklistList = 'blacklist',
    BlacklistCreate = 'blacklist-create',
    BlacklistEdit = 'blacklist-edit',
}

export enum MarketingKeys {
    VoucherList = 'vouchers',
    VoucherCreate = 'voucher-create',
    VoucherDetail = 'voucher-detail',
}

export enum VehiclesKeys {
    ProducerList = 'producers',
    ProducerEdit = 'producer-edit',
    ProducerCreate = 'producer-create',
    ModelList = 'models',
    ModelEdit = 'model-edit',
    ModelCreate = 'model-create',
    SchemaList = 'schemas',
    SchemaEdit = 'schema-edit',
    SchemaCreate = 'schema-create',
}

export enum ProductsKeys {
    ProductList = 'products',
    ProductCreate = 'product-create',
    ProductEdit = 'product-edit',
    ParametersList = 'parameters',
    ParameterCreate = 'parameter-create',
    ParameterEdit = 'parameter-edit',
    CategoryList = 'product-categories',
    CategoryCreate = 'product-category-create',
    CategoryEdit = 'product-category-edit',
}

export enum AdminKeys {
    Pulse = 'pulse',
}

export enum EmailTemplatesKeys {
    EmailTemplatesList = 'email-templates',
    EmailTemplateCreate = 'email-template-create',
    EmailTemplateDetail = 'email-template-detail',
}

export enum ImportKeys {
    BatchList = 'batches',
    BatchDetail = 'batch-detail',
    NewImport = 'new-import',
}
export enum OrdersKeys {
    OrderList = 'orders',
    OrderDetail = 'order-detail',
    SupplierOrderList = 'supplier-orders',
    SupplierOrderDetail = 'supplier-order-detail',
    SupplierOrderNew = 'supplier-order-new',
}
